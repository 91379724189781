import styled from 'styled-components';
import { media } from 'styles/mixins';

const Wrapper = styled.footer`
  background-color: var(--primary-color);
  color: var(--color-white);
  padding: 84px 0;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  *::selection {
    background-color: #FFDD00;
    color: black;
  }

  ${media.tablet`
    padding: 84px 16px;
  `}
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const Blocks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-auto-rows: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 36px;

  ${media.tablet`
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
  `}
`;

const Block = styled.div`
  border: 1px solid var(--azure-color);
  border-radius: 8px;
  padding: 24px;
  
  &.clear {
    border: none;
    padding: 0;
    line-height: 21px;
  }
`;

const BlockHeading = styled.div`
  font-weight: 500;
  margin-bottom: 20px;
`;

const BlockBody = styled.ul``;

const BlockLine = styled.li`
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const BlockName = styled.div`
  margin-bottom: 4px;
`;

const BlockValue = styled.div`
  display: inline-block;
  color: var(--color-grey);
  word-break: break-all;
`;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column !important;

  h3 {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Documents = styled(MainBlock)`
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;
`;

const DocumentsHeading = styled.h3``;

const DocumentsBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const Document = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
`;

const DocumentHeading = styled.h4`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin: 22px 0 4px;
`;

const DocumentBody = styled.div`
  .preview {
    display: block;
    height: 140px;
    padding: 7px 4px;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const ContactUs = styled(MainBlock)`
  margin-bottom: 44px;
`;

const FollowUs = styled(MainBlock)``;

const Socials = styled.ul`
  display: flex;
`;

const Social = styled.li`
  display: flex;
  margin-right: 20px;
  margin-top: 20px;
  
  &:hover a {
    border-color: var(--color-white);
  }
  
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1.5px solid var(--color-grey);
    border-radius: 10px;
  }
`;

const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Contact = styled.a`
  font-size: 16px;
  line-height: 19px;
  color: var(--color-grey);
  
  &:hover {
    color: var(--color-white);
  }
  
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export {
  Wrapper,
  Container,
  Blocks,
  Block,
  BlockHeading,
  BlockBody,
  BlockLine,
  BlockName,
  BlockValue,
  Documents,
  DocumentsHeading,
  DocumentsBody,
  Document,
  DocumentHeading,
  DocumentBody,
  ContactUs,
  FollowUs,
  Socials,
  Social,
  Contacts,
  Contact
};
