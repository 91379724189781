import styled from 'styled-components';
import { media } from 'styles/mixins';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding: 100px 0 270px;

  ${media.tablet`
    flex-direction: column;
    padding: 48px 16px;
    align-items: center;
  `}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 590px;
  max-width: 50%;
  align-items: center;
  text-align: center;
  color: var(--primary-color);
  font-size: 24px;
  line-height: 120%;

  ${media.tablet`
    font-size: 20px;
    max-width: none;
    width: 100%; 
  `}
  
  div {
    color: var(--secondary-color);
    font-size: 14px;
    line-height: 18px;
  }
  
  h1 {
    line-height: 120%;
    font-size: 52px;
    font-family: 'Fira Sans', sans-serif;
    margin-bottom: 44px;
    margin-top: 84px;

    ${media.tablet`
     font-size: 28px;
     margin-top: 44px;
     margin-bottom: 32px;
  `}
  }
  
  p {
    margin-bottom: 44px;

    ${media.tablet`
      font-size: 18px;
    `}
  }
  
  ul {
    display: flex;
    margin-bottom: 84px;
    
    ${media.tablet`
      justify-content: space-around;
      margin-bottom: 44px;
      flex-wrap: wrap;
    `}

    li {
      display: flex;
      align-items: center;
      margin: 0 16px;

      ${media.tablet`
        margin: 0 0 8px;
      `}
      
      svg {
        position: relative;
        top: 1px;
        margin-right: 8px;
      }
      
      span {
        font-weight: 500;
      }
    }
  }
  
  .btn {
    padding: 12px 28px;
    height: 46px;
    background-color: #074952;
    background-image: linear-gradient(83.45deg, #0F1830 -6.08%, #074952 -3.17%, #0F1830 105.76%);
    border-radius: 4px;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    letter-spacing: 0.02em;
    transition: all 0.3s;
    
    &:hover, &:active {
      background-image: none;
    }
  }
`;

const Media = styled.div`
  width: 100%;
  max-width: 606px;
  overflow: hidden;

  ${media.tablet`
    margin-bottom: 48px;
  `}
  
  svg {
    position: absolute;
    bottom: 100px;
  }
`;

export { Content, Info, Media };
