import { LanguageSwitcher } from 'components/Layout/Header/LanguageSwitcher';

// Hooks
import { usePageLocale } from 'hooks/usePageLocale';
import { usePageId } from 'hooks/usePageId';

// Images
import Logo from 'assets/images/huh-full.svg';

// Styles
import {
  Wrapper,
  Container,
} from './styles';

export const Header = () => {
  const { pageId } = usePageId();
  const { pageLocale } = usePageLocale();

  return (
    <Wrapper id="header">
      <Container>
        <Logo/>
        <LanguageSwitcher />
      </Container>
    </Wrapper>
  );
}
