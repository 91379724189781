import { useState, useEffect } from "react";
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { usePageLocale } from 'hooks/usePageLocale';
import { useLocales } from 'hooks/useLocales';

import { Icon } from '@iconify/react';
import { Select } from "./styles";

export const LanguageSwitcher = () => {
  const { allSitePage: { pageNodes } } = useStaticQuery(graphql`
    query {
      allSitePage {
        pageNodes: nodes {
          path
          pageContext
        }
      }
    }
  `);
  const { pageLocale } = usePageLocale();
  const { locales } = useLocales();
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const handleLanguageChange = (event) => {
    const value = event.target.value;
    setSelectedLanguage(value === 'uk' ? '/' : value);
  };

  useEffect(() => {
    navigate(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Select>
      <select defaultValue={pageLocale} onChange={handleLanguageChange}>
        {locales.map((locale, i) => {
          return (
            <option key={i} value={locale}>
              {locale === 'uk' ? 'ua' : locale}
            </option>
          );
        })}
      </select>
      <Icon icon="material-symbols:keyboard-arrow-down" />
    </Select>
  );
};
