import { Link as GatsbyLink } from 'gatsby';

import styled from 'styled-components';

const Select = styled.div`
  display: flex;
  position: relative;
  height: 44px;

  select {
    color: var(--secondary-color);
    appearance: none;
    outline: none;
    border: none;
    width: 55px;
    font-weight: 600;
    font-size: 22px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    background-color: transparent;
    text-transform: uppercase;
  }
  
  svg {
    color: var(--secondary-color);
    height: 24px;
    width: 24px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
`;

export { Select };
