import { graphql, useStaticQuery } from 'gatsby';

import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import { getPathMatch } from '../../../functions/getPathMatch';
import { useLocales } from '../../../hooks/useLocales';
import { usePageLocale } from '../../../hooks/usePageLocale';
import { usePageId } from '../../../hooks/usePageId';

export const PageHead = ({ seoTitle, seoDescription, seoImage }) => {
  const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
              siteUrl
          }
        }
#      allDatoCmsSeoAndPwa {
#        seoAndPwaNodes: nodes {
#          locale
#          siteName
#          separator
#          fallbackDescription
#          defaultOgImage {
#            url
#          }
#          pwaThemeColor {
#            themeHexColor: hex
#          }
#        }
#      }
        allSitePage {
            pageNodes: nodes {
                path
                pageContext
            }
        }
    }
  `);

  const {
    // allDatoCmsSeoAndPwa: { seoAndPwaNodes },
    allSitePage: { pageNodes },
    site: { siteMetadata: { siteUrl } },
  } = data;

  const { pageId } = usePageId();
  const { href } = useLocation();
  const { pageLocale } = usePageLocale();
  const { defaultLocale, locales } = useLocales();

  return (
    <Helmet>
      {/* HTML lang and dir attrs */}

      <html lang={pageLocale} dir="ltr" />

      {locales.map((locale) => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={`${siteUrl}${getPathMatch({ renderingLocale: locale, pageNodes, pageId })}`}
        />
      ))}

      <link rel="canonical" href={`${siteUrl}${getPathMatch({ renderingLocale: pageLocale, pageNodes, pageId })}`} />

      {/* SEO meta tags */}

      <title>Help us heal!</title>
      <meta
        name="description"
        content="We are a Charitable Foundation, “Help Us Heal” that carries out charitable activities in the field of medical care and health care for victims of the Russian-Ukrainian war"
      />
      <meta property="og:type" content="organization" />

      <link rel="apple-touch-icon" sizes="180x180" href="assets/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="assets/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="assets/favicons/favicon-16x16.png" />
      <link rel="manifest" href="assets/favicons/site.webmanifest" />
      <link rel="mask-icon" href="assets/favicons/safari-pinned-tab.svg" color="#5bbad5"/>
      <meta name="msapplication-TileColor" content="#da532c"/>
      <meta name="theme-color" content="#ffffff"/>
    </Helmet>
  );
};
