import styled from 'styled-components';
import { media } from 'styles/mixins';

const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  padding: 0 60px;
  height: 85px;
  align-items: center;
  justify-content: space-between;

  ${media.tablet`
    padding: 0 20px;
  `}
  
  svg {
    height: 44px;
  }
`;

export {
  Wrapper,
  Container,
};
