import { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { usePageLocale } from 'hooks/usePageLocale';

import Image from 'components/Image';

import IconInstagram from 'assets/icons/icon-instagram.svg';
import IconFacebook from 'assets/icons/icon-facebook.svg';
import IconLinkedin from 'assets/icons/icon-linkedin.svg';
import IconTwitter from 'assets/icons/icon-twitter.svg';

import {
  Wrapper,
  Container,
  Blocks,
  Block,
  BlockHeading,
  BlockBody,
  BlockLine,
  BlockName,
  BlockValue,
  Documents,
  DocumentsHeading,
  DocumentsBody,
  Document,
  DocumentHeading,
  DocumentBody,
  ContactUs,
  FollowUs,
  Socials,
  Social,
  Contacts,
  Contact
} from './styles';

export const Footer = () => {
  const { pageLocale } = usePageLocale();
  const dataQuery = useStaticQuery(graphql`
      fragment FooterData on DatoCmsFooter {
          documentsText
          documents {
              heading
              preview {
                  gatsbyImageData(imgixParams: {h: "125", w: "90"})
              }
              document {
                  url
              }
          }
          paymentMethods {
              heading
              paymentItems {
                  name
                  value
              }
          }
          privacyText
          policyText
          copyrightText
          contactUsText
          followUsText
      }
      query {
          footerEn: datoCmsFooter(locale: "en") {
              ...FooterData
          }
          footerUk: datoCmsFooter(locale: "uk") {
              ...FooterData
          }
      }`
  );

  const { footerEn, footerUk } = dataQuery;
  const data = pageLocale === "en" ? footerEn : footerUk;

  const {
    documentsText,
    documents,
    paymentMethods,
    privacyText,
    policyText,
    copyrightText,
    contactUsText,
    followUsText
  } = data;

  return (
    <Wrapper id="footer">
      <Container>
        <Blocks>
          <Block>
            <Documents>
              <DocumentsHeading>{documentsText}</DocumentsHeading>
              <DocumentsBody>
                {documents.map((document, i) => {
                  const { heading, preview, document: file } = document;
                  return (
                    <Document key={i}>
                      <DocumentHeading>{heading}</DocumentHeading>
                      <DocumentBody>
                        <a className="preview" href={file ? file.url : "#"}>
                          <Image image={preview} alt={heading}/>
                        </a>
                      </DocumentBody>
                    </Document>
                  )
                })}
              </DocumentsBody>
            </Documents>
            <ContactUs>
              <h3>{contactUsText}</h3>
              <Contacts>
                <Contact href="mailto:contact@helpusheal.ngo">contact@helpusheal.ngo</Contact>
                <Contact href="tel:380506540649">+38 (050) 654 0649</Contact>
              </Contacts>
            </ContactUs>
            <FollowUs>
              <h3>{followUsText}</h3>
              <Socials>
                <Social><a href="#"><IconInstagram/></a></Social>
                <Social><a href="#"><IconFacebook/></a></Social>
                <Social><a href="#"><IconLinkedin/></a></Social>
                <Social><a href="#"><IconTwitter/></a></Social>
              </Socials>
            </FollowUs>
          </Block>
          {paymentMethods.map((method, i) => {
            const { heading, paymentItems } = method;
            return (
              <Block key={i}>
                <BlockHeading>{heading}</BlockHeading>
                <BlockBody>
                  {paymentItems.map((item, i) => {
                    const { name, value } = item;
                    return (
                      <BlockLine key={i}>
                        <BlockName>{name}</BlockName>
                        <BlockValue>{value}</BlockValue>
                      </BlockLine>
                    )
                  })}
                </BlockBody>
              </Block>
            )
          })}
          <Block className="clear">{privacyText}</Block>
          <Block className="clear">{policyText}</Block>
          <Block className="clear">{copyrightText}</Block>
        </Blocks>
      </Container>
    </Wrapper>
  );
};
