import { graphql } from 'gatsby';
import { Icon } from '@iconify/react';

import { PageWrapper } from '../../components/Layout/PageWrapper';
import Image from 'components/Image';

import { Content, Media, Info } from './styles';

const HomeTemplate = ({ data: { datoCmsMvpPage: pageData }, pageContext }) => {
  const { seoTitle, seoDescription, seoImage } = 'seo' || {};
  const { heading, description, services, donateButtonText, donateButtonLink, mvpText, mainImage } = pageData;
  const servicesList = services.split(",");

  return (
    <PageWrapper {...{ seoTitle, seoDescription, seoImage }} pageData={pageContext}>
      <Content>
        <Media>
          <Image image={mainImage} alt={heading} />
        </Media>
        <Info>
          <div>{mvpText}</div>
          <h1>{heading}</h1>
          <p>{description}</p>
          <ul>
            {servicesList.map((service, i) => (
              <li key={i}>
                <Icon icon="material-symbols:arrow-right-alt" />
                <span>{service}</span>
              </li>
            ))}
          </ul>
          <a className="btn btn-donate" href={donateButtonLink}>{donateButtonText}</a>
        </Info>
      </Content>
    </PageWrapper>
  );
};

export default HomeTemplate;

export const query = graphql`
    query HomepageQuery($locale: String!) {
        datoCmsMvpPage(locale: $locale) {
            heading
            description
            services
            donateButtonText
            donateButtonLink
            mvpText
            mainImage {
                gatsbyImageData
            }
        }
    }
`;
