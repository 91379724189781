import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { isSvg, isGif } from '../../functions/imageUtils';

function Image({ image, alt }) {
  const { format, url, gatsbyImageData } = image || {};
  const data = getImage(gatsbyImageData);
  const isRaster = isSvg({ format }) || isGif({ format });

  return isRaster ? (
    <img src={url} alt={alt} />
  ) : (
    <GatsbyImage alt={alt || ''} image={data} />
  );
}

export default Image;
