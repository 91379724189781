import { createGlobalStyle } from 'styled-components';

import { easeOutTiming } from './animation';

// media queries
export const breakpoints = {
  mobile: '480px',
  mobileM: '660px',
  tabletS: '980px',
  tablet: '1024px',
  laptopS: '1280px',
  laptop: '1440px',
  desktopS: '1600px',
  desktop: '1920px',
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --base-font: -apple-system, BlinkMacSystemFont, sans-serif;

    --weight-light: 300;
    --weight-regular: 400;
    --weight-medium: 500;
    --weight-bold: 700;

    // colors
    --primary-color: #0F1830;
    --secondary-color: #074952;
    --azure-color: #396D75;
    --color-white: #fff;
    --color-grey: #B7BAC1;
    --color-black: #000;

    --success-color: #65B923;
    --error-color: rgb(229, 18, 18);
    --error-background: rgb(229, 18, 18, .05);

    // buttons
    --primary-button-color: var(--primary-color);

    // animation
    --transition-delay: .3s;
    --transition-duration: .3s;
    --timing-function: ease-out;

    // variables
    --gap: 2rem;
    --gap-mobile: 1rem;

    --border: .0625rem;
    --border-radius: 0.25rem;
    --box-radius: 0.375rem;
    --card-radius: .75rem;

    --z-index-tooltip: -1;
    
    --z-index-header: 2;
    --header-height: 4.25rem;
    --header-height-mobile: 3.75rem;
    
    --progress-bar-height: 0.125rem;
    --box-shadow: 0 .25rem 1.5rem 0 var(--color-black-25);

    --form-width: 27.5rem;
    --text-container-width: 35rem;
    --questions-container-width: 41.875rem;
    
    // theme
    --base-background-color: var(--color-white);
    --header-background-color: var(--color-white);
    --footer-background-color: var(--primary-color);
    --primary-text-color: var(--color-black);
    --secondary-text-color: var(--primary-color);
    --button-text-color: var(--color-white);
    --primary-button-background-color: var(--primary-color);
    --secondary-button-background-color: var(--secondary-color);
    --form-background-color: var(--color-white);
    --input-border-color-active: var(--primary-color);
    --input-background-color-active: var(--color-white);
    }

    ::selection {
      color: var(--primary-color);
      background: var(--secondary-color);
    }

    * {
      -webkit-tap-highlight-color: transparent;
    }

    *,
    *::after,
    *::before {
      box-sizing: border-box;
    }

    html {
      font-size: 100%;
      line-height: 1.5;
      font-family: var(--base-font);
      background-color: var(--base-background-color);
      transition: background-color ${easeOutTiming};
      scroll-behavior: smooth;
      padding: 0;
      margin: 0;
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: var(--weight-regular);
      color: var(--primary-text-color);
      margin: 0;
    }

    p,
    h1,
    h2,
    h3 {
      margin: 0;
      padding: 0;
    }

    ul,
    ol {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    button {
      margin: 0;
      padding: 0;
      border: none;
      cursor: pointer;
      appearance: none;
      background: none;
    }

    input,
    button,
    select,
    textarea {
      font-family: inherit;
      line-height: 1.15;
      font-size: 100%;
      margin: 0;
    }

    svg {
      display: -webkit-box;
    }

    a { 
      text-decoration: none;
      color: var(--primary-text-color);
      transition: color ${easeOutTiming};
    
      @media (hover: hover) {
        &:hover {
          color: var(--secondary-text-color);
        }
      }

      &.activeClassLink {
        color: var(--secondary-text-color);
        text-decoration: underline;
        cursor: default;
      }
    }

    b,
    strong {
      font-weight: var(--weight-bold);
    }
`;
