import { LocaleProvider } from 'contexts/locale';

import { PageHead } from './Head/PageHead';
import { MainContainer } from '../UI/Containers';
import { Header } from './Header';
import { Footer } from './Footer';

export const PageWrapper = ({
  pageData,
  seoTitle,
  seoDescription,
  seoImage,
  pageScript,
  children,
}) => (
  <div style={{ position: 'relative' }}>
    <LocaleProvider pageData={pageData}>
      <PageHead
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoImage={seoImage}
        pageScript={pageScript}
      />
      <Header />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </LocaleProvider>
  </div>
);
